import styled from '@emotion/styled';
import Image, { ImageLoaderProps } from 'next/image';
import { useAppContext } from '../../contexts/AppContext';
import { useEditorHandlers } from './@editor';

const Root = styled('div')(({ theme }) => ({
  background: theme.theme_component_footer_background || 'white',
  marginTop: theme.spacing(8),
}));

const Container = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme?.theme_component_footer_e_font_size || '14px',
  color: theme?.theme_component_footer_e_font_color || 'black',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  border: theme?.theme_component_footer_e_border_top || '1px solid #d0d0d0',
}));

const AzerionBrandContainer = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
}));

const AzerionBrandContent = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
  marginLeft: '30px',
  padding: '10px 0',
}));

export const componentContentDefault = '<div> From PUBLISH_DATE, the operating company of this platform has changed (the service can be used as it is).</div><div>This game is produced and operated by <a href="https://www.azerion.com/" target="_blank" rel="noreferrer" style="text-decoration: underline">Azerion</a> and is deployed on <a href="/" target="_blank" rel="noreferrer" style="text-decoration: underline">YOUR_SITENAME.</a></div>'

export const FooterBarD = () => {
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const azerionBrand = componentOptions?.component_footer_bar_azerion_footer ;

  return (
    <Root {...useEditorHandlers('Footer Bar D')}>
      <Container>
        <div style={{ textAlign: 'center' }}>
          <div>
            本ゲームは
            <a href="https://www.azerion.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
              Azerion
            </a>
            が制作・運営し
            <a
              href="https://www.yahoo.co.jp/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Yahoo! JAPAN
            </a>
            のサービスYahoo!ゲームで展開しています。
          </div>
          <div>
            <a
              href="https://games.yahoo.co.jp/docs/notice/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              LINEヤフー株式会社等によるcookie情報等の利用について
            </a>
          </div>
          <div>
            ©
            <a href="https://www.azerion.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
              Azerion
            </a>
          </div>
        </div>
      </Container>
      {azerionBrand && (
        <AzerionBrandContainer>
          <AzerionBrandContent>
            <Image
              src="/images/azerion-brand.png"
              loader={({ src, width }: ImageLoaderProps) => `${src}?w=${width || 180}`}
              height="30"
              alt="made-with-love"
              width="180"
            />
          </AzerionBrandContent>
        </AzerionBrandContainer>
      )}
    </Root>
  );
};
