import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';
import { componentContentDefault } from '../FooterBarD';
export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_footer_background',
        label: 'Background color',
        type: 'color',
        default: '#003367',
      },
      {
        name: 'theme_component_footer_e_font_color',
        label: 'Font color',
        type: 'color',
        default: '#000',
      },
      {
        name: 'theme_component_footer_e_border_top',
        label: 'Footer Border Top',
        type: 'text',
        default: '1px solid #d0d0d0',
      },

      {
        name: 'theme_component_footer_e_font_size',
        label: 'Font size',
        type: 'text',
        default: '14px',
      },
    ],
    configuration: [
      {
        name: 'component_footer_bar_azerion_footer',
        label: 'Azerion branded footer visible',
        type: 'checkbox',
        default: false,
      },
      {
        name: 'component_advertisement_show',
        label: 'Show Sticky Advertisement',
        type: 'checkbox',
        default: false,
      },
      {
        name: 'component_footer_bar_d_content',
        label: 'Footer Bar Content',
        type: 'html',
        default: componentContentDefault,
        helperText: 'Footer Bar content',
      },
    ],
  });
};
